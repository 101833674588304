import {uid} from "uloc-vue"

export default function (
    projeto, cliente
) {
  let window = 'console.tarefa.new-' + uid()
  let css = window.replace(/\./g, '-')
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Registrar tarefa',
    width: '800',
    height: '800',
    minHeight: '500px',
    maxHeight: '500px',
    backdrop: true,
    move: false,
    clickOutside: false,
    windowClass: 'erp-window-modern no-close-btn',
    contentClass: '',
    props: {
      $router: this.$router,
      router: this.$router,
      projeto,
      cliente
    }
  }, () => import('./criarTarefaWindow'))
      .then((wid) => {
        console.log(wid)
        this.$uloc.window.listen(wid, {
          updated: (wid, val) => {
            console.log('Update event')
          },
          created: (wid, val) => {
            console.log('Create event')
          }
        })
      }) // return wid
}
