import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 1
export const listName = 'console.tasks.lista'
export const listStorage = tableColumnsCache(listName, [
    {label: 'Resumo', name: 'titulo', active: true, sortable: true, ordering: 0},
    {label: 'Fila', name: 'queue', active: true, sortable: true, ordering: 1},
    {label: 'ID', name: 'id', active: true, sortable: true, ordering: 2},
    {label: 'Tipo', name: 'tipo', active: true, sortable: true, ordering: 3},
    {label: 'Relator', name: 'relator', active: true, sortable: true, ordering: 5},
    {label: 'Criado', name: 'dataRegistro', active: true, sortable: true, ordering: 6},
    {label: 'Cliente', name: 'cliente', active: false, sortable: true, ordering: 7},
    {label: 'Projeto', name: 'projeto', active: true, sortable: true, ordering: 8},
    {label: 'Responsável', name: 'participantes', active: true, sortable: true, ordering: 9},
    {label: 'Progresso', name: 'progress', active: true, sortable: true, ordering: 10},
    {label: 'Status', name: 'status', active: true, sortable: true, ordering: 12},
    {label: 'SLA de entrega', name: 'dataPrazo', active: true, sortable: true, ordering: 11},
], listVersion)
