<script>
import {list} from '@/domain/tarefas/services'
import {UTable, UTh, UTr, UTd, UCheckbox, date, LocalStorage, UTooltip, UProgress} from 'uloc-vue'
import MenuOptions from '@/components/layout/context-menu/context-window-options'
import {ErpInput, /*ErpSelect,*/ ErpSField} from 'uloc-vue-plugin-erp'
//import DefaultMixin from '../mixin'
import StatusSelect from "components/suporte/components/task/include/StatusSelect"
import {datePtToEn} from '@/utils/date'
import ECol from "components/layout/components/Col.vue"
import EBtn from "@/plugins/uloc-erp/components/button/EBtn"
import ERow from "components/layout/components/Row.vue"
import FiltrosAdicionais from "components/console/components/suporte/tarefas/include/ListFilters.vue"
import listOptions from "components/usuario/v2/window/listOptions"
import filters from "@/domain/tarefas/helpers/filtersConsole"
import {addDays, differenceInHours, endOfWeek, format, parseISO, startOfWeek, subDays} from "date-fns";
import {listName, listStorage} from "./config/list"
import TaskGroupPersons from "components/sltarefas/components/others/TaskGroupPersons.vue"
import CheckSvg from "@/assets/images/CheckSvg.vue"
import AlertSvg from "@/assets/images/AlertSvg.vue"
import criarTarefaWindow from "components/console/components/suporte/tarefas/window/criarTarefa";

export default {
  name: 'ConsoleTarefasList',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [
      //DefaultMixin
  ],
  components: {
    AlertSvg,
    CheckSvg,
    ERow,
    ECol,
    // DateInput,
    StatusSelect,
    // SearchFilter,
    UTable,
    UTh,
    UTr,
    UTd,
    //UCheckbox,
    ErpInput,
    EBtn,
    // ErpSelect,
    ErpSField,
    FiltrosAdicionais,
    UTooltip,
    TaskGroupPersons,
    UProgress
  },
  beforeCreate() {
    this.listStorage = listStorage
  },
  data () {
    return {
      listName: listName,
      listStorage: this.listStorage,
      filtersData: {
        uf: {
          loading: true,
          serverData: []
        }
      },
      showFilters: false,
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 10, // specifying this determines pagination is server-side
          rowsPerPage: 20
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      },
      stats: {
        cadastradosHoje: 0,
        cadastradosSemana: 0,
        paraAnalisar: 0
      },
      statsFilterActive: null
    }
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    },
    menuDashOptions () {
      return MenuOptions
    }
  },
  mounted () {
    this.load()
  },
  destroyed () {
  },
  methods: {
    listOptions: listOptions,
    updateListDefinition(newConfig) {
      LocalStorage.set(this.listName, newConfig)
      this.listStorage = newConfig
    },
    isOpen (task) {
      return task.status.type === 'opened'
    },
    atraso (d) {
      return Math.ceil(differenceInHours(parseISO(d.date || d), new Date()) / 24) * -1
    },
    abrir(t) {
      this.$router.push({
        name: 'console.tarefa',
        params: {
          id: t.id
        }
      })
    },
    trClass (task) {
      const css = []
      if (this.colorize) {
        if (task.deadline && this.atraso(task.deadline) > 0) {
          css.push('sla-out')
        }
      }
      return css
    },
    request({pagination, filter}) {

      // this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || (!this.table.filters.data2 || this.table.filters.data2.length < 10)) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.table.filters.tipoData && extraFilters.push(`&typeDate=${this.table.filters.tipoData}`)

      this.table.filters.search && extraFilters.push(`&search=${String(this.table.filters.search).replace('#', '_hash-')}`)

      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      !this.fila && this.table.filters.internalStatus && extraFilters.push(`&internalStatus=${this.table.filters.internalStatus.join(',')}`)
      this.table.filters.tipo && extraFilters.push(`&type=${this.table.filters.tipo}`)
      this.table.filters.active && extraFilters.push(`&active=${this.table.filters.active}`)
      this.table.filters.cliente && extraFilters.push(`&cliente=${this.table.filters.cliente.id}`)
      this.table.filters.assigned && extraFilters.push(`&assigned=${this.table.filters.assigned.id}`)
      this.table.filters.author && extraFilters.push(`&author=${this.table.filters.author.id}`)
      this.table.filters.lider && extraFilters.push(`&owner=${this.table.filters.lider.id}`)
      this.table.filters.permission && extraFilters.push(`&permission=${this.table.filters.permission}`)
      this.table.filters.public && extraFilters.push(`&public=${this.table.filters.public}`)
      this.table.filters.project && extraFilters.push(`&project=${this.table.filters.project?.id}`)
      this.table.filters.priority && extraFilters.push(`&priority=${this.table.filters.priority.id}`)
      this.table.filters.type && extraFilters.push(`&type=${this.table.filters.type.id}`)
      this.table.filters.fila && extraFilters.push(`&queue=${this.table.filters.fila.replace('id:', '')}`)
      this.table.filters.queue && extraFilters.push(`&queue=${this.table.filters.queue.id}`)
      // Filtra somente tarefas vencidas
      this.table.filters.dueFilter && extraFilters.push(`&dueFilter=${this.table.filters.dueFilter}`)
      this.table.filters.status && extraFilters.push(`&status=${this.table.filters.status.id}`)
      this.table.filters.typeFilter && extraFilters.push(`&typeFilter=${this.table.filters.typeFilter}`)

      if ((!this.table.filters.internalStatus || !this.table.filters.internalStatus.length) && !this.table.filters.status) {
        extraFilters.push(`&internalStatus=opened`)
      }

      /*if (Array.isArray(this.tags) && this.tags.length > 0) {
        extraFilters.push(`&tags=${this.tags.join(',')}`)
      }*/

      if (this.extraFilters && Array.isArray(this.extraFilters)) {
        this.extraFilters.map(ef => {
          extraFilters.push('&' + ef)
        })
      }

      this.table.loading = true
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'createdAt'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : true}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros, true)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    load () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    clearFilters () {
      this.table.filters = JSON.parse(JSON.stringify(filters))
    },
    novo () {
      console.log('New...')
    },
    pesquisar () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.search
      })
    },
    tabFilter (t) {
      if (this.table.filters.typeFilter === t) {
        this.table.filters = JSON.parse(JSON.stringify(filters))
        this.load()
        return
      }
      this.table.filters = JSON.parse(JSON.stringify(filters))
      this.table.filters.typeFilter = t
      this.load()
    },
    criarTarefaWindow,
    criarTarefa () {
      this.criarTarefaWindow(null, null)
    }
  },
  meta: {
    title: 'Suporte SL - Fila',
    name: 'Suporte'
  }
}
</script>

<template>
  <div class="erp-list-task">
    <div class="flex justify-between">
      <div class="console-task-opts">
        <div class="cleft"></div>
        <div class="cright">
          <u-btn @click="criarTarefa" no-caps color="green" class="tbtn">
            <i class="fa-duotone fa-folder-open m-r-sm" /> Criar
          </u-btn>
        </div>
      </div>
      <!--      <div class="flex justify-end m-t m-b-lg">
              <u-btn @click="novo" class="sl-btn" color="green" no-caps v-shortkey="['shift', 'n']"
                     @shortkey.native="novo">Cadastrar <span class="shortcut font-10 m-l">Shift + N</span></u-btn>
            </div>-->
    </div>
    <u-table
        ref="table"
        color="primary"
        :data="table.serverData"
        :columns="table.columns"
        :visible-columns="visibledColumns"
        :filter="table.busca"
        row-key="id"
        :pagination.sync="table.serverPagination"
        @request="request"
        selection="multiple"
        :selected.sync="table.selected"
        :loading="table.loading"
        class="erp-table table-v2 table-shadow"
        :rows-per-page-options="[20, 50, 100]"
    >
      <template slot="top" slot-scope="props">
        <div class="sl-v2-table-filters full-width">
          <e-row class="e-input-modern size1">
            <e-col style="min-width: 150px" class="m-l m-r">
              <erp-s-field
                  view="ll"
                  label="Busca"
              >
                <erp-input placeholder="Busca Inteligente" v-on:keyup.enter="pesquisar" shortkey="F2" v-model="table.filters.search" />
              </erp-s-field>
            </e-col>
            <e-col style="max-width: 100px" class="m-l m-r">
              <erp-s-field
                  view="ll"
                  label="ID"
              >
                <erp-input v-on:keyup.enter="pesquisar" shortkey="F3" v-model="table.filters.id" />
              </erp-s-field>
            </e-col>
            <e-col style="min-width: 150px" class="m-l m-r">
              <erp-s-field view="ll" label="Status">
                <status-select v-model="table.filters.status" placeholder="Somente ativos" />
              </erp-s-field>
            </e-col>
            <e-col style="max-width: 80px">
              <e-btn @click="pesquisar" label="Filtrar" />
              <!--                  <u-btn @click="pesquisar" color="grey" size="sm" class="app-btn erp-btn text-white" no-caps>Filtrar</u-btn>-->
            </e-col>
            <e-col class="flex items-end content-end justify-end">
              <u-btn @click="showFilters = !showFilters" color="blue-grey-1" size="sm" class="app-btn text-blue-grey-5" no-caps>
                <!--    Mais filtros-->
                <u-icon name="filter" class="m-l__ m-r-sm fin-icon-filter" type="fa"/>
                <span v-if="!showFilters">Mais Filtros</span>
                <span v-else>Menos Filtros</span>
              </u-btn>
            </e-col>
          </e-row>
          <e-row>
            <filtros-adicionais :filters="table.filters" v-show="showFilters" />
          </e-row>
        </div>
      </template>
      <u-tr slot="header" slot-scope="props">
        <!--        <u-th auto-width v-if="mode !== 'cliente'">
                  <u-checkbox
                      v-model="props.selected"
                      :indeterminate="props.partialSelected"
                      size="xs"
                  />
                </u-th>-->
        <u-th v-for="col in props.cols" :key="col.name" :props="props" :class="'col-' + col.name">
          <div v-if="col.name === 'options'" class="text-center">
            <u-btn style="margin: -10px 0 -6px" @click="listOptions" label="Configurar lista" rounded round flat no-caps size="xs" icon="cog" icon-type="fa" color="grey-4" class="text-grey-8"/>
          </div>
          <template v-else>{{col.label}}</template>
        </u-th>
      </u-tr>

      <u-tr slot="body" slot-scope="props"
            :props="props" :class="[{'u-table-item-destac': props.row.isToday}, trClass(props.row)]">
        <!--        <u-td class="pos-rlt" auto-width v-if="mode !== 'cliente'">
                  <u-checkbox color="primary" v-model="props.selected" size="xs"/>
                </u-td>-->
        <u-td :title="props.row.resumo" class="td-limit l-400 col-titulo" key="titulo"
              :props="props">
          <router-link tag="a" :to="{
        name: 'console.tarefa',
        params: {
          id: props.row.id
        }
      }">{{ props.row.description }}</router-link>
        </u-td>
        <u-td style="max-width: 50px; min-width: 50px; width: 50px" key="queue" :props="props">
          <span class="text-blue-grey-4">{{ props.row.queuePosition }}</span>
        </u-td>
        <u-td style="max-width: 50px; min-width: 50px; width: 50px" key="id" :props="props" class="col-id">
          {{ props.row.id }}
        </u-td>
        <u-td class="td-limit l-200 col-tipo" key="tipo" :props="props">
          {{ props.row.type ? props.row.type.name : '-' }}
        </u-td>
        <u-td key="relator" :props="props" class="td-limit l-130">
          <span v-if="props.row.author">{{props.row.author.name}}</span>
        </u-td>
        <u-td class="td-limit" key="dataRegistro" :props="props">
          <div v-if="props.row.createdAt" style="cursor: default">
            {{ props.row.createdAt.date|formatDate('dd/') }}{{
              props.row.createdAt.date|formatDate('LLL')
            }}{{ props.row.createdAt.date|formatDate('/yy') }}
            <u-tooltip content-class="bg-indigo font-11" :offset="[15, 15]">
              {{ props.row.createdAt.date|formatDate('dd/MM/yyyy HH:ii:ss') }}
            </u-tooltip>
          </div>
          <div v-else>Nunca</div>
        </u-td>
        <u-td class="td-limit" key="cliente" :props="props">
          <div v-if="props.row.cliente">
            {{ props.row.cliente.name }}
          </div>
          <div v-else>-</div>
        </u-td>
        <u-td key="projeto" :props="props">
          <div v-if="props.row.project">{{props.row.project.name}}</div>
        </u-td>
        <u-td class="td-limit today" style="max-width: 80px; width: 80px" key="participantes" :props="props">
          <div v-if="props.row.cache && props.row.cache.participants">
            <task-group-persons :persons="props.row.cache.participants" no-click />
          </div>
          <span v-else></span>
        </u-td>
        <u-td class="td-limit today" key="progress" :props="props">
          <div class="flex items-center no-wrap">
            <u-progress class="progress-sgrp m-r"
                        :percentage="props.row.progress"
                        color="positive" height="6px" style="border-radius: 5px; max-width: 70%"></u-progress>
            <span style="min-width: 18px" class="font-10 m-r-xs">{{ props.row.progress }}%</span>
          </div>
        </u-td>
        <u-td class="text-uppercase col-status" key="status" :props="props">
          <div :class="'task-status-' + props.row.status.code">
            {{props.row.status ? props.row.status.name : '-' }}
          </div>
        </u-td>
        <u-td class="td-limit today col-dataPrazo" key="dataPrazo" :props="props">
          <div v-if="props.row.deadline">
            <span class="dl-dt">{{ props.row.deadline|formatDate('dd/MM/yyyy') }}</span> <span class="dl-hr">{{ props.row.deadline|formatDate('HH:mm') }}</span>
            <span class="m-l-xs trask-atraso-lbl" v-if="isOpen(props.row) && atraso(props.row.deadline) > 0"><i
                class="fas fa-exclamation-triangle m-r-xs"></i> {{ atraso(props.row.deadline) }} dia{{ atraso(props.row.deadline) > 1 ? 's' : '' }}</span>
          </div>
          <span v-else>Sem previsão</span>
        </u-td>
        <u-td key="options">
          <span v-if="props.row.status.code === 'completed'"><check-svg /></span>
          <span v-if="props.row.status.code === 'recused'"><alert-svg /></span>
        </u-td>
        <!--        <u-td class="text-center" key="options" :props="props">
                </u-td>-->
      </u-tr>
    </u-table>
  </div>
</template>
