<template>
  <div class="h-full">
    <div v-show="$route.name === 'console.tarefas'" class="console-tarefas console-pad">
      <div class="cliente-tarefas">
        <task-list-page ref="list" :autoload="true"/>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>

import TaskListPage from "@/components/console/components/suporte/tarefas/TarefasList.vue"

export default {
  name: "ClienteTarefas",
  data() {
    return {
      response: {},
    }
  },
  components: {
    TaskListPage
  },
  mounted() {
  },
  methods: {
  }
}
</script>
